<template>
  <div>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Code</label>

            <b-form-select
              :options="formattedProjectOptions"
              size="lg"
              v-model="form.project.code"
              @change="setProject($event)"
            ></b-form-select>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Nickname</label>

            <b-form-input
              id="input-large"
              size="lg"
              v-model="form.project.nickname"
              disabled
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Meeting Number</label>

            <b-form-input
              id="input-large"
              size="lg"
              v-model="form.code"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Datetime</label>

            <b-form-input
              id="input-large"
              size="lg"
              type="datetime-local"
              v-model="form.date"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Agenda</label>

            <b-form-input
              id="input-large"
              size="lg"
              v-model="form.subject"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Leader</label>

            <b-form-input
              id="input-large"
              size="lg"
              v-model="form.leader"
            ></b-form-input>
          </div>

          <div class="col-md-12 py-2 col-sm-12">
            <label for="input-large">Place</label>

            <b-form-input
              id="input-large"
              size="lg"
              v-model="form.place"
            ></b-form-input>
          </div>

          <div class="col-md-12 py-2 col-sm-12">
            <label for="input-large">Status</label>

            <b-form-select
              v-model="form.status"
              :options="statusOptions"
              size="lg"
            ></b-form-select>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <b-button
              class="ml-auto mr-2"
              variant="success"
              :disabled="loading"
              @click="save"
            >
              <i class="flaticon2-check-mark"></i> Save
            </b-button>

            <b-button
              class="ml-auto mr-2"
              variant="secondary"
              :disabled="loading"
              @click="toIndexPage"
            >
              <i class="flaticon2-cancel-music"></i> Cancel
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapState } from "vuex";
import { GET_PROJECT_DETAIL } from "../../../../core/services/store/project.module";
// import CompleteTable from "../../content/widgets/advance-table/CompleteTable";
import { arrayMoreThanOne } from "../../../../core/helper/array-validation.helper";
import { SAVE_MEETING } from "../../../../core/services/store/meeting.module";

export default {
  components: {
    // CompleteTable
  },
  data() {
    return {
      form: {
        project: {
          code: null,
          nickname: null,
        },
        code: null,
        date: null,
        place: null,
        subject: null,
        pic: null,
        leader: null,
        division: null,
        department: null,
        status: null,
      },
      statusOptions: [
        {
          text: "Choose Status",
          value: null,
        },
        {
          text: "Open",
          value: "open",
        },
        {
          text: "Close",
          value: "close",
        },
      ],
    };
  },

  computed: {
    ...mapState({
      loading: (state) => state.meeting.loading,
      projects: (state) => state.projectDetails.projectDetails?.data,
    }),

    formattedProjectOptions() {
      let projects = [];

      if (arrayMoreThanOne(this.projects)) {
        projects = this.projects.map((project) => {
          return {
            value: project.code,
            text: project.code,
          };
        });
      }

      return [{ value: null, text: "Choose Project" }, ...projects];
    },
  },

  methods: {
    getProjects() {
      this.$store.dispatch(GET_PROJECT_DETAIL, {
        jobdesk: "design",
        params: ["interface", "design", "admin"],
      });
    },

    setProject(value) {
      const project = this.projects.find((project) => project.code === value);

      if (project) {
        this.form.project.nickname = project.nickname;
      } else {
        this.form.project.nickname = null;
      }
    },

    save() {
      const form = JSON.parse(JSON.stringify(this.form));

      if (!this.form.project.code) {
        form.project = null;
      }

      this.$store
        .dispatch(SAVE_MEETING, {
          ...form,
        })
        .then(() => {
          this.toIndexPage();
        });
    },

    toIndexPage() {
      this.$router.push({
        name: "meeting",
      });
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Create Meeting" }]);

    this.getProjects();
  },
};
</script>
